* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

main {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

::selection {
  background: #000;
  color: #fff;
}

:root {
  --body-background: #111;
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-background: #111;
    user-select: none;
  }
}

html {
  background-color: #000;
}

body {
  font-family: sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'NotoColorEmoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiSymbols';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  cursor: default;
  color: rgba(255, 255, 255, 0.8);
}

a {
  position: relative;
  transition: all 0.2s;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

strong {
  font-weight: 500;
}

em {
  font-style: italic;
  letter-spacing: 0.5px;
}

button {
  font-weight: 400;
  font-family: inherit;
  -webkit-appearance: none;
  border: none;
  border-radius: unset;
  padding: 0;
  margin: 0;
  height: 2rem;
  text-align: unset;
}

.cf:before,
.cf:after {
  content: ' ';
  display: table;
}

.cf:after {
  clear: both;
}

.di {
  clear: both;
}


html {
  max-width: 390px;
  max-height: 844px;
  margin: auto;
}
