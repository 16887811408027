html {
  --header-height: 0px;
  --header-offset: calc(env(safe-area-inset-top) * 0.8);
  --view-height: calc(844px - var(--header-height));
}

.view {
  width: 100%;
  min-height: var(--view-height);
  display: grid;
  align-items: start;
  align-content: start;
}

.view.center,
.view .frame .container.center {
  align-items: stretch;
  align-content: stretch;
}

.bind,
.view .frame {
  width: 100%;
  max-width: 1660px;
  margin: 0 auto;
}

.view .frame {
  padding: calc(3rem + env(safe-area-inset-top)) 3rem 0;
  display: grid;
  gap: 3rem;
}

.view .frame.has-header {
  padding: 3rem;
}

.view .frame.has-sub-views {
  grid-template-columns: 60px 1fr;
}

.view .frame .container {
  display: grid;
  gap: 3rem;
  align-content: start;
}

@media screen and (max-width: 600px) {
  .view .frame {
    padding: calc(2rem + env(safe-area-inset-top)) 2rem 0;
    gap: 2rem;
  }

  .view .frame.has-header {
    padding: 2rem;
  }

  .view .frame.has-sub-views {
    grid-template-columns: 40px 1fr;
  }

  .view .frame.has-sub-views.horizontal-sub-views {
    grid-template-columns: 1fr;
    grid-template-rows: 40px 1fr;
  }

  .view .frame .container {
    gap: 2rem;
  }
}

#root > .wrapper {
  background-color: var(--body-background);
  color: rgba(255, 255, 255, 0.8);
  transition: background-color 1s;
}

@media (any-pointer: fine) {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-draggy);
    border-radius: 0px;
  }
}

#root > .wrapper.dark #footer {
  color: rgba(255, 255, 255, 0.6);
}

#root > .wrapper.dark #footer ul li:last-child a {
  color: rgba(255, 255, 255, 0.8);
}

h2 {
  font-size: 18px;
  line-height: 1;
  margin: 0 0 8px;
}

h3 {
  font-size: 28px;
  line-height: 1.1;
  font-weight: 500;
  margin: 0 0 1rem;
}

h4 {
  font-size: 16px;
}

h4,
h5,
h6 {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 0 1rem;
}

h6 {
  margin: 0 0 10px;
}

h4:not(:first-child),
h5:not(:first-child) {
  margin-top: 2rem;
}

h6:not(:first-child) {
  margin-top: 1rem;
}

h4,
h5,
h6 {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 0 0 6px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

h5 {
  padding: 0 0 3px;
  border-bottom-width: 1px;
}

h6 {
  padding: 0;
  border-bottom: none;
}

h4.no-underline,
h5.no-underline {
  padding-bottom: unset;
  border-bottom: unset;
}

h4.small-caps {
  text-transform: uppercase;
  font-family: var(--font-text);
  font-size: 12px;
  letter-spacing: 1px;
  border-bottom: 0px;
  padding: 0;
  margin-bottom: 12px;
}

h4 span {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
}

h4 span span:first-child {
  color: rgba(255, 255, 255, 0.8);
}

h4 + h5,
h4 + h5:not(:first-child) {
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 24px;
  }
}

p {
  line-height: 1.6;
  margin: 0 0 6px;
}

p:last-child {
  margin: 0;
}

p code {
  padding: 2px 1px;
  background-color: rgba(255, 255, 255, 0.1);
  font-family: monospace;
  font-size: 14px;
  line-height: 1;
}

.hyperlink {
  text-decoration: underline;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-style: solid;
  text-decoration-color: rgba(255, 255, 255, 0.2);
  text-underline-position: under;
}

.hyperlink:active {
  color: #ffffff;
  text-decoration-color: rgba(255, 255, 255, 0.6);
}

@media (hover: hover) {
  .hyperlink:hover {
    color: #ffffff;
    text-decoration-color: rgba(255, 255, 255, 0.6);
  }
}

.image:not(.padded) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.5s;
}

.image.padded {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s;
}

.image.dl {
  opacity: 1;
}

.wrapper.reduced-motion .image {
  transition: unset;
}

.sticky-nav {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  font-size: 14px;
  user-select: none;
  background-color: hsla(0, 0%, 3%, 0.2);
  backdrop-filter: blur(12px) brightness(1.1);
}

.sticky-nav.page {
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
}

.sticky-nav > .wrapper {
  padding: 0 3rem;
  margin: 0 auto;
  max-width: 1660px;
  height: 60px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
}

.sticky-nav.mini > .wrapper {
  height: 40px;
}

.sticky-nav .destiny-key,
.sticky-nav i {
  font-size: 16px;
}

.sticky-nav .button .icon {
  display: grid;
  width: 20px;
  opacity: 0.8;
}

.sticky-nav .button .icon svg {
  fill: #ffffff;
}

.sticky-nav ul li .button .text {
  color: #fff;
}

.sticky-nav ul {
  display: grid;
  grid-auto-flow: column;
}

.sticky-nav ul li .button {
  width: fit-content;
  height: 52px;
  padding: 0 1rem;
  background-color: rgba(255, 255, 255, 0);
}

.sticky-nav.mini ul li .button {
  height: 32px;
  color: #fff;
}

.sticky-nav ul li .button:active {
  background-color: rgba(255, 255, 255, 0.4);
}

@media (hover: hover) {
  .sticky-nav ul li .button:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.sticky-nav.inline {
  padding: 0 1rem;
}

@media screen and (max-width: 600px) {
  .sticky-nav {
    position: sticky;
  }

  .sticky-nav.inline {
    padding: 0;
  }

  .sticky-nav > .wrapper {
    padding: 0 2rem;
    font-size: 12px;
  }

  .sticky-nav ul {
    gap: 0;
  }

  .sticky-nav .destiny-key {
    margin-top: 2px;
  }
}

.wrapper.dark .sticky-nav {
  background-color: hsla(0, 0%, 3%, 0.4);
}

html.no-backdrop-filter .wrapper .sticky-nav {
  background-color: hsla(0, 0%, 83%, 0.6);
}

html.no-backdrop-filter .wrapper.dark .sticky-nav {
  background-color: hsla(0, 0%, 3%, 0.8);
}
