#app-nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: calc((env(safe-area-inset-bottom) / 2) + 70px);
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

#app-nav a {
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
  display: grid;
  gap: 3px;
  justify-items: center;
  align-content: center;
  position: relative;
  transform: translateZ(0px);
  opacity: 0.5;
  transition: opacity 0.25s;
}

#app-nav a .icon svg {
  width: 18px;
  fill: white;
}

#app-nav a .text {
  font-size: 12px;
  padding: 0 0 0 3px;
}

#app-nav a.active {
  opacity: 0.9;
}

#app-nav a:active {
  opacity: 1;
}
